// extracted by mini-css-extract-plugin
export var header = "layout-module--header--UmhLx";
export var header_container = "layout-module--header_container--U225E";
export var header_container_home = "layout-module--header_container_home--3EaG-";
export var nullbug_logo = "layout-module--nullbug_logo--3uVHv";
export var logo_svg = "layout-module--logo_svg--1bZvj";
export var header_title = "layout-module--header_title--2oqYg";
export var header_cta = "layout-module--header_cta--11pH-";
export var header_cta_button = "layout-module--header_cta_button--3_zNp";
export var form = "layout-module--form--3YYCW";
export var social_icons = "layout-module--social_icons--seCWc";
export var logo = "layout-module--logo---MXHy";
export var svg_social_icon = "layout-module--svg_social_icon--S5cJm";
export var start_project = "layout-module--start_project--IAGcY";
export var blog_link = "layout-module--blog_link--8CrRA";