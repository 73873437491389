import React, { useEffect, useState } from "react"
import { Link } from "gatsby"

import WhatsApp from "../assets/svg/whats.svg"
import NullbugLogo from "../assets/svg/nullbug-logo.svg"
import { i18n } from "../translate/i18n"
import ".././translate/i18n"
import { checkIsJsonString } from "../utils/checkIsJsonString"
import { rejectAllCookies } from "../utils/rejectAllCookies"
import { rejectPerformanceCookies } from "../utils/rejectPerformanceCookies"
import { rejectAdvertisingCookies } from "../utils/rejectAdvertisingCookies"

import * as styles from "./layout.module.css"
import Grid from "./grid"
import BigNoBoring from "./big_no_boring"
import Footer from "./Footer"
import { Dropdown } from "../components/Dropdown"
import Cookies from "../components/Cookies/cookies"
import Management from "../components/Cookies/management"

const I18N_STORAGE_KEY = "i18nextLng"

const Header = () => {
  const [language, setLanguage] = useState("")

  const onChangeLanguage = ({ value }) => {
    localStorage.setItem(I18N_STORAGE_KEY, value)
    setLanguage(value)
    window.location.reload()
  }

  useEffect(() => {
    const defaultLanguage = localStorage.getItem(I18N_STORAGE_KEY)

    if (!!defaultLanguage?.length) {
      setLanguage(defaultLanguage)
    }
  }, [])

  return (
    <>
      <section className={styles.logo}>
        <Link to="/" className={styles.nullbug_logo}>
          <NullbugLogo className={styles.logo_svg} />
        </Link>
      </section>

      <section className={styles.social_icons}>
        <a
          href="https://api.whatsapp.com/send?phone=554626011561&text=Ol%C3%A1!%20Tenho%20uma%20ideia%20e%20gostaria%20de%20torn%C3%A1-la%20realidade"
          aria-label="WhatsApp Link"
        >
          <WhatsApp className={styles.svg_social_icon} />
        </a>
        <a href="/blog" className={styles.blog_link}>
          Blog
        </a>
        <a href="/hire-us" className={styles.start_project}>
          {i18n.t("buttons.startProject")}
        </a>

        <Dropdown
          value={language}
          options={[
            {
              value: "pt-BR",
              label: "PT",
            },
            {
              value: "en-US",
              label: "EN",
            },
            {
              value: "es-ES",
              label: "ES",
            },
          ]}
          onChange={onChangeLanguage}
        />
      </section>
    </>
  )
}

const Layout = ({ location, children, headerHome }) => {
  const [displayDisclaimer, setDisplayDisclaimer] = useState(true)
  const [openManagementModal, setOpenManagementModal] = useState(false)
  const [performanceCookies, setPerformanceCookies] = useState(false)
  const [advertisingCookies, setAdvertisingCookies] = useState(false)

  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath

  useEffect(() => {
    if (localStorage.getItem("cookiePermissions")) {
      setDisplayDisclaimer(false)
    }
  }, [])

  useEffect(() => {
    const storagedCookiePermissions = localStorage.getItem("cookiePermissions")
    const cookiePermissions = checkIsJsonString(storagedCookiePermissions)
      ? JSON.parse(storagedCookiePermissions)
      : storagedCookiePermissions

    if (
      cookiePermissions?.performanceCookies === "accepted" &&
      cookiePermissions?.advertisingCookies === "rejected"
    ) {
      setPerformanceCookies(true)
      setAdvertisingCookies(false)
      rejectAdvertisingCookies()
    } else if (
      cookiePermissions?.performanceCookies === "rejected" &&
      cookiePermissions?.advertisingCookies === "accepted"
    ) {
      setAdvertisingCookies(true)
      setPerformanceCookies(false)
      rejectPerformanceCookies()
    } else if (cookiePermissions === "allRejected") {
      setPerformanceCookies(false)
      setAdvertisingCookies(false)
      rejectAllCookies()
    } else if (cookiePermissions === "allAccepted") {
      setPerformanceCookies(true)
      setAdvertisingCookies(true)
    }
  }, [location, openManagementModal])

  return (
    <div data-is-root-path={isRootPath}>
      <div
        className={`${styles.header_container} ${
          headerHome ? styles.header_container_home : ""
        }`}
      >
        <Grid>
          {headerHome ? (
            <header className={`${styles.header}`}>
              <Header />
              <section className={`${styles.header_title}`}>
                <h1>{i18n.t("titles.tPrincipal")}</h1>
                <div className={`${styles.header_cta}`}>
                  <Link className={`${styles.header_cta_button}`} to="/hire-us">
                    {i18n.t("messages.startProject")}
                  </Link>
                </div>
              </section>
              <BigNoBoring />
            </header>
          ) : (
            <header className={styles.header}>
              <Header />
            </header>
          )}
        </Grid>
      </div>
      {children}
      {displayDisclaimer && (
        <Cookies
          setDisplayDisclaimer={setDisplayDisclaimer}
          setOpenManagementModal={setOpenManagementModal}
          setPerformanceCookies={setPerformanceCookies}
          setAdvertisingCookies={setAdvertisingCookies}
        />
      )}
      <Management
        setDisplayDisclaimer={setDisplayDisclaimer}
        openManagementModal={openManagementModal}
        setOpenManagementModal={setOpenManagementModal}
        performanceCookies={performanceCookies}
        setPerformanceCookies={setPerformanceCookies}
        advertisingCookies={advertisingCookies}
        setAdvertisingCookies={setAdvertisingCookies}
      />
      <Footer setOpenManagementModal={setOpenManagementModal} />
    </div>
  )
}

export default Layout
