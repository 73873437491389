import * as React from "react"

import { i18n } from "../../translate/i18n"

import * as styles from "./footer.module.css"
import NullbugLogo from "../../assets/svg/nullbug-logo.svg"
import Grid from "../grid"

// Social Icons
import Instagram from "../../assets/svg/instagram.svg"
import Behance from "../../assets/svg/behance.svg"
import Linkedin from "../../assets/svg/linkedin.svg"

function Footer({ setOpenManagementModal }) {
  return (
    <footer>
      <Grid>
        <section className={styles.container}>
          <NullbugLogo className={styles.logo} />
          <p className={styles.font_ocr}>
            {i18n.t("address.addressStreet")} <br />
            {i18n.t("address.addressBuilding")} <br />
            {i18n.t("address.addressRoom")} <br />
          </p>
          <p className={styles.font_ocr}>
            &lt;cep&gt; =<br />
            85605-060
            <br />
          </p>
          <p className={styles.font_ocr}>
            {i18n.t("address.addressAbbrev")} =<br />
            {i18n.t("address.addressCity")}
            <br />
          </p>
          <p>
            <a
              href="https://www.instagram.com/null.bug/"
              aria-label="Instagram link"
            >
              <Instagram className={styles.svg_social_icon} />
            </a>
            <a
              href="https://www.behance.net/nullbug"
              arial-label="Behance link"
            >
              <Behance className={styles.svg_social_icon} />{" "}
            </a>
            <a
              href="https://www.linkedin.com/company/null-bug/"
              arial-label="Linkedin link"
            >
              <Linkedin className={styles.svg_social_icon} />
            </a>
          </p>
        </section>
        <section className={styles.container}>
          <h3 className={styles.contato_title}>
            {i18n.t("startingProject.strContact")}
          </h3>
          <p className={styles.font_ocr}>
            (46) 2601-1561 <br />
            <a href="mailto:contato@nullbug.dev" target="_blank">
              contato@nullbug.dev
            </a>
          </p>
          <section>
            <h3 className={styles.contato_title}>
              {i18n.t("startingProject.strPrivacy")}
            </h3>
            <a
              href="mailto:dpo@nullbug.dev"
              target="_blank"
              className={styles.font_ocr}
            >
              dpo@nullbug.dev
            </a>
          </section>
        </section>
      </Grid>
      <section className={styles.endSection}>
        <small className={styles.copyright}>
          &copy; Copyright {new Date().getFullYear()}.{" "}
          {i18n.t("footer.footerRights")} {i18n.t("footer.footerCompany")} CNPJ:
          37.978.623/0001-43.
        </small>
        <div className={styles.links}>
          <a href="/docs" className={styles.link}>
            {i18n.t("startingProject.strDocs")}
          </a>
          <button
            className={styles.link}
            onClick={() =>
              setOpenManagementModal(
                openManagementModal => !openManagementModal
              )
            }
          >
            {i18n.t("cookiesMsg.cksManage")}
          </button>
        </div>
      </section>
    </footer>
  )
}

export default Footer
