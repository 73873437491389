import React from "react"

import "./management.css"
import { i18n } from "../../translate/i18n"
import { rejectAllCookies } from "../../utils/rejectAllCookies"
import { rejectPerformanceCookies } from "../../utils/rejectPerformanceCookies"
import { rejectAdvertisingCookies } from "../../utils/rejectAdvertisingCookies"
import CookiePolicy from "../../docs/CookiePolicy.pdf"

const Management = ({
  setDisplayDisclaimer,
  openManagementModal,
  setOpenManagementModal,
  performanceCookies,
  setPerformanceCookies,
  advertisingCookies,
  setAdvertisingCookies,
}) => (
  <div
    className="modal"
    style={{ display: openManagementModal ? "block" : "none" }}
  >
    <div className="content">
      <div className="title_container">
        <p className="title">{i18n.t("managementCookiesMsg.title")}</p>
        <button
          onClick={() =>
            setOpenManagementModal(openManagementModal => !openManagementModal)
          }
          style={{ cursor: "pointer" }}
        >
          X
        </button>
      </div>
      <p className="subtitle">
        {i18n.t("managementCookiesMsg.subtitle")}{" "}
        <a id="underlineLink" href={CookiePolicy}>
          {i18n.t("managementCookiesMsg.link")}
        </a>
      </p>
      <div className="body">
        <div className="cookies_content">
          <div>
            <div className="title_container">
              <p className="title">
                {i18n.t("managementCookiesMsg.necessaryCookiesTitle")}
              </p>
              <label className="switch">
                <input checked disabled type="checkbox" onChange={() => ({})} />
                <span className="slider round" />
              </label>
            </div>
            <p>{i18n.t("managementCookiesMsg.necessaryCookies")}</p>
          </div>
          <div>
            <div className="title_container">
              <p className="title">
                {i18n.t("managementCookiesMsg.performanceCookiesTitle")}
              </p>
              <label className="switch">
                <input
                  type="checkbox"
                  checked={performanceCookies}
                  onChange={() =>
                    setPerformanceCookies(
                      performanceCookies => !performanceCookies
                    )
                  }
                />
                <span className="slider round" />
              </label>
            </div>
            <p>{i18n.t("managementCookiesMsg.performanceCookies")}</p>
          </div>
          <div>
            <div className="title_container">
              <p className="title">
                {i18n.t("managementCookiesMsg.advertisingCookiesTitle")}
              </p>
              <label className="switch">
                <input
                  type="checkbox"
                  checked={advertisingCookies}
                  onChange={() =>
                    setAdvertisingCookies(
                      advertisingCookies => !advertisingCookies
                    )
                  }
                />
                <span className="slider round" />
              </label>
            </div>
            <p>{i18n.t("managementCookiesMsg.advertisingCookies")}</p>
            <p>{i18n.t("managementCookiesMsg.responsibility")}</p>
          </div>
          <div>
            <p className="title">
              {i18n.t("managementCookiesMsg.configTitle")}
            </p>
            <p className="subtitle2">
              {i18n.t("managementCookiesMsg.configSubtitle")}
            </p>
            <div className="links">
              <a
                className="link"
                href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=pt-BR"
              >
                Chrome
              </a>
              <a
                className="link"
                href="https://support.mozilla.org/pt-BR/kb/desative-cookies-terceiros-impedir-rastreamento"
              >
                Firefox
              </a>
              <a
                className="link"
                href="https://support.microsoft.com/pt-br/microsoft-edge/excluir-cookies-no-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09"
              >
                Microsoft Edge
              </a>
              <a
                className="link"
                href="https://support.microsoft.com/pt-br/windows/excluir-e-gerenciar-cookies-168dab11-0753-043d-7c16-ede5947fc64d"
              >
                Internet Explorer
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="save_button_container">
        <button
          className="save_button"
          onClick={() => {
            if (performanceCookies && advertisingCookies) {
              localStorage.setItem("cookiePermissions", "allAccepted")
            } else if (performanceCookies && !advertisingCookies) {
              rejectAdvertisingCookies()
              localStorage.setItem(
                "cookiePermissions",
                JSON.stringify({
                  performanceCookies: "accepted",
                  advertisingCookies: "rejected",
                })
              )
            } else if (advertisingCookies && !performanceCookies) {
              rejectPerformanceCookies()
              localStorage.setItem(
                "cookiePermissions",
                JSON.stringify({
                  performanceCookies: "rejected",
                  advertisingCookies: "accepted",
                })
              )
            } else {
              rejectAllCookies()
              localStorage.setItem("cookiePermissions", "allRejected")
            }

            setOpenManagementModal(openManagementModal => !openManagementModal)
            setDisplayDisclaimer(false)
          }}
        >
          {i18n.t("managementCookiesMsg.save")}
        </button>
      </div>
    </div>
  </div>
)

export default Management
