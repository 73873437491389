export const messages = {
  // HOME PAGE
  es: {
    translations: {
      titles: {
        tPrincipal:
          "> Diseño y desarrollo de productos digitales sin la molestia de desarrolladores tradicionales _",
        tExpertise: "> Experiencia _",
        tOurClients: "< Nuestros clientes _>",
        tCases: "< Cases seleccionados >",
        tTechnologies: "< Tecnologías que utilizamos >",
        tMoreThanCode: "> Entregamos algo más que el código _",
        tEndToEnd:
          "> Creación de principio a fin, para construir los mejores productos y servicios digitales _",
        tAbout: "about",
        tTechPeoples: "> Tecnología hecha por personas _",
        tNewsletter: "Newsletter",
      },
      messages: {
        startProject: "Iniciar un proyecto >>",
        msgExpertise:
          "Más de 20 años de experiencia en el desarrollo de proyectos " +
          "para los principales actores del mercado nos consolidan para tener en nuestra " +
          "cartera empresas que son referencia en sus segmentos.",
        msgCases: "> Nos gusta que nuestro trabajo hable por nosotros _",
        msgIsohub:
          "> Una experiencia diseñada para toda la rutina de una agencia de graduación _",
        msgSCSeg:
          "> Gestión con solo un click, condominios automatizados y gestionados mediante la plataforma _",
        msgKitt:
          "> Una herramienta para automatizar tareas, gestionando las tareas rutinarias de manera rápida y organizada _",
        msgBierHeld:
          "> Una completa aplicación de ventas para una de las mayores cervecerías de Brasil _",
        msgDiscover:
          "Identificamos los puntos de acción en cada escenario de aplicación, centrándonos en el usuario, no sólo en el código",
        msgDesign:
          "Antes de empezar a poner todo en el código, configuramos el prototipo. De esta manera podemos validar la idea rápidamente",
        msgDevelop: "Con entregas frecuentes, la aplicación se va construyendo",
        msgDeliver: "La entrega final",
        msgAbout1:
          "Null Bug nació del deseo de ofrecer diseño y desarrollo de productos digitales de forma dinámica.",
        msgAbout2:
          "Entendiendo las necesidades reales de los clientes, nosotros acabamos con las promesas y nos enfocamos en lo que marcará la diferencia para usted.",
        msgTechPeoples:
          "> Contamos con un equipo multidisciplinar, con distintas especialidades para cubrir todas las necesidades de desarrollo " +
          "de productos digitales. Desde su inicio hasta su entrega y colocación en el mercado, garantizamos la calidad en los más mínimos detalles.",
        msgNewsletter:
          "¿Quiere saber más? Suscríbase a nuestro newsletter, conozca las estrategias para mejorar la gestión, descubra las mejores " +
          "prácticas y manténgase en sintonía de nuestros contenidos sobre tecnología.",
      },
      buttons: {
        startProject: "Iniciar un proyecto",
        buttonSign: "INSCRIBIRSE",
      },
      tags: {
        tagDiscover: "Descubrir",
        tagDraw: "Diseñar",
        tagDevelop: "Desarrollar",
        tagDeliver: "Entregar",
        tagTechnology: "Tecnología",
        tagDesign: "Diseño",
        tagBusiness: "Negocios",
        tagUx: "UX",
        tagProgramming: "Programación",
        tagFace: "Reconocimiento Facial",
      },
      address: {
        addressStreet: "Calle Palmas, 1943,",
        addressBuilding: "Edificio Solaris,",
        addressRoom: "Sala 801 y 802, 8° piso - Centro",
        addressAbbrev: "<cd/EDO>",
        addressCity: "Francisco Beltrão/PR - Brasil",
      },
      cookiesMsg: {
        cksTitle: "AVISO DE COOKIES",
        cksContent:
          'Le informamos que nuestro sitio web utiliza cookies y tecnologías similares para mejorar su experiencia. Si continúa navegando, asumimos que conoce nuestro Aviso de cookies y que está de acuerdo con nuestra Política. Por lo tanto, si hace clic en aceptar, tendrá acceso a todas las funciones del sitio. Si rechaza, se deshabilitarán las cookies que no sean estrictamente necesarias. Si lo prefiere, puede personalizar su elección definiendo qué cookies desea autorizar haciendo clic en "Administrar cookies". Para obtener más información, visite nuestra',
        link: "Política de cookies.",
        cksManage: "Administrar cookies",
        cksReject: "Rechazar cookies",
        cksAccept: "Acepto cookies",
      },
      managementCookiesMsg: {
        title: "GESTIONAR COOKIES",
        subtitle: "Para obtener más información, visite nuestra",
        link: "Política de cookies.",
        necessaryCookiesTitle: "Cookies estrictamente necesarias",
        necessaryCookies:
          "Son cookies esenciales que permiten la navegación en nuestras aplicaciones y el acceso a todas las características y funcionalidades, las cuales no pueden ser desactivadas en nuestros sistemas. Puede bloquear estas cookies directamente en su navegador, en cuyo caso nuestros servicios pueden funcionar mal o no funcionar en absoluto.",
        performanceCookiesTitle: "Cookies de rendimiento",
        performanceCookies:
          "Estas cookies tienen como objetivo mejorar el rendimiento del sitio web mediante la recopilación de datos anónimos sobre la navegación y el uso de los recursos disponibles, que pueden ser recopilados por terceros. No es necesario que permita la recopilación de estas cookies; sin embargo, estos datos no se pueden utilizar para mejorar el sitio web.",
        advertisingCookiesTitle: "Cookies publicitarias",
        advertisingCookies:
          "Estas son cookies establecidas por terceros de confianza que se utilizan, por ejemplo, para publicidad y marketing, dirigiendo anuncios personalizados según su interés. También puede desactivarlos.",
        responsibility:
          "NullBug no tiene control sobre qué cookies de terceros se configuran. Algunas cookies de terceros que se pueden encontrar al acceder a nuestro sitio web tienen el dominio de Google y Facebook, y están más detalladas en nuestra Política.",
        configTitle: "Configuración de cookies del navegador",
        configSubtitle:
          "Puede deshabilitarlos cambiando la configuración de su navegador",
        save: "Guardar",
      },
      // STARTING A PROJECT PAGE
      startingProject: {
        strSuccess: "Hacemos que su idea sea un éxito",
        strCompany: "¿Cuál es el nombre de su empresa o de su proyecto?*",
        strName: "¿Cuál es su nombre?*",
        strEmail: "¿Cuál es su correo electrónico?*",
        strPhone: "¿Cuál es su número de teléfono?",
        strCanDo: "¿Qué puede hacer Null Bug por usted?",
        strProduct: "Crear un producto increíble, partiendo desde cero",
        strGreater: "Hacer que su producto actual sea aún mejor",
        strServers: "Ayuda para el mantenimiento del servidor",
        strOther: "Otro",
        strTellUs: "¿Podría hablarnos más a respecto?",
        strBudget: "¿Cuál es su presupuesto?",
        strLessThan: "Menos que R$ 25.000,00",
        strOrMore: "R$ 100.000,00 o más",
        strNotDefined: "No determinado",
        strMeetUs: "¿Cómo se enteró de nuestra existencia?",
        strSend: "MANDAR",
        strRegister:
          "Se ha registrado. Nos pondremos en contacto con usted en breve.",
        strThanks: "Gracias!",
        strContact: "Contacto",
        strPrivacy: "Privacidad",
        strDocs: "Términos, Avisos y Política de Privacidad",
        strNamePlaceholder: "Su nombre",
        strEmailPlaceholder: "Su correo electrónico",
        strSendButton: "MANDAR",
      },
      footer: {
        footerRights: "Todos los derechos reservados.",
        footerCompany:
          "NULLBUG DESARROLLO DE PROGRAMA DE COMPUTADORA Y CAPACITACIÓN DE INFORMÁTICA LTDA.",
      },
      docs: {
        dataProtection: "Cómo Protegemos sus Datos",
        portal: "Portal de Comunicación del Sujeto de Datos",
        privacy: "Aviso de Privacidad",
        termsAndConditions: "Términos y Condiciones de Uso",
        cookiePolicy: "Política de Cookies",
      },
      dataProtection: {
        p1:
          'El propósito de redactar este documento tal como se presenta es demostrarle a usted, el titular de los datos, de manera accesible y resumida, los puntos principales de nuestro Aviso de Privacidad. Para obtener más información, visite nuestro <a id="underlineLink" href="/docs">Descargo de Responsabilidad</a> completo.',
        p2: "Descubra cómo usamos y protegemos sus datos personales.",
        p3:
          "En este sentido, nuestro objetivo es aclarar qué información se recopila de los usuarios de nuestros sitios web y sus respectivos productos y servicios, y cómo se tratan estos datos.",
        p4:
          "En caso de desacuerdo, no comparta su información para usar nuestros servicios y productos, ni use nuestro sitio web. Sin embargo, le pedimos que aclare el motivo del desacuerdo para que podamos analizar sus sugerencias y, eventualmente, revisar nuestro <b>Aviso</b>.",
        topic1: {
          title: "¿QUÉ ES LA LGPD?",
          p1:
            "La <b>Ley General de Protección de Datos (LGPD)</b> prevé el tratamiento de datos personales, con el fin de velar por sus derechos y garantizar el uso responsable y protegido de sus datos.",
        },
        topic2: {
          title: "¿CUÁLES SON TUS DERECHOS Y CÓMO PUEDE EJERCITARLOS?",
          p1:
            "Según el arte. 18 de la Ley General de Protección de Datos, el interesado podrá solicitar información y medidas en relación con sus datos personales.",
          p2: `Puede ejercer sus derechos cuando lo desee a través de nuestro <a id="underlineLink" href="/docs/portal">Portal de comunicación de interesados</a>.<br/><br/>`,
          p3: "Los <b>derechos</b> de los Titulares de Datos Personales son:",
          laws: {
            1: "confirmación de la existencia del tratamiento;",
            2: "acceso a los datos;",
            3: "corrección de datos incompletos, inexactos o desactualizados;",
            4: "anonimización, bloqueo o eliminación de datos excesivos e innecesarios;",
            5: "portabilidad de los datos a otro proveedor de servicios o productos, previa solicitud expresa, de conformidad con las normas de la autoridad nacional, observando secretos comerciales e industriales;",
            6: "supresión de los datos personales tratados con el consentimiento del titular;",
            7: "información de entidades públicas y privadas con las que el controlador realizó uso compartido de datos;",
            8: "información sobre la posibilidad de no dar el consentimiento y sobre las consecuencias de la negativa;",
            9: "revocación del consentimiento; es",
            10: "revisión de decisiones automatizadas.<br/><br/>",
          },
          p4:
            "Es importante tener en cuenta que sus derechos no son absolutos. En algunas situaciones, podemos retener información para cumplir con nuestras obligaciones legales y reglamentarias, o para el ejercicio regular de nuestros derechos. En este caso, se le informará siempre que sea necesario.",
        },
        topic3: {
          title: "PRECAUCIONES QUE DEBE TOMAR",
          p1:
            "Le advertimos que no enviaremos mensajes electrónicos con confirmaciones a través de enlaces y archivos que puedan ejecutarse en sus dispositivos, por lo que nunca envíe sus datos por canales no oficiales. Además, tenga cuidado con sus contraseñas personales y los sitios a los que accede.",
        },
        topic4: {
          title: "LA INFORMACIÓN QUE RECOPILAMOS",
          content: {
            title1: "<b>Información proporcionada por usted</b>",
            p1:
              "De la información proporcionada por usted al completar los formularios disponibles en nuestros sitios web y aplicaciones, <b>NullBug</b> recopilará aquellos de identificación personal como, por ejemplo, nombre, correo electrónico y número de teléfono. Eventualmente, la solicitud de otra información podrá realizarse a través del contacto directo de <b>NullBug</b> con los usuarios, personalmente o vía e-mail o teléfono, para la ejecución de un eventual contrato.",
            title2: "<b>Información de navegación del sitio web</b>",
            p2: `Al acceder a nuestros sitios web y aplicaciones, es posible que se instalen cookies en su navegador. Para obtener más información, visite nuestra <a id="underlineLink" href="/docs">Política de cookies</a>.`,
            title3: "<b>Historial de contactos</b>",
            p3:
              "<b>NullBug</b> almacena información sobre todos los contactos ya realizados con nuestros usuarios, como interacciones personales, por teléfono o por correo electrónico.",
            title4:
              "<b>Datos generados al utilizar nuestros servicios o solicitar nuestros productos</b>",
            p4:
              "Si utiliza alguno de nuestros servicios, solicita nuestros productos, o incluso en los casos en que sea cliente de nuestros clientes y la prestación del servicio involucre sus datos, <b>NullBug</b> podrá recopilar información necesaria para la ejecución del servicio. contrato de prestación del Servicio o suministro del Producto. En este caso, se podrán recopilar datos de contacto como su nombre completo, CPF, razón social, correo electrónico, teléfono y dirección.",
          },
        },
        topic5: {
          title: "PARA QUÉ UTILIZAMOS SU INFORMACIÓN",
          p1:
            "Los datos personales que recabamos son utilizados para la ejecución de contratos de Prestación de Servicios o Suministro de Productos, siempre respetando su privacidad. Por tanto, sus datos sólo serán utilizados para las finalidades aquí descritas y autorizadas por usted.",
          p2:
            "Podemos utilizar sus datos para crear perfiles basados ​​en sus datos de comportamiento y comprobar cuáles son sus intereses, para clasificar y calificar de acuerdo con este perfil, y para crear listas dinámicas para realizar acciones de marketing dirigidas, pero en este caso, solo cuando con su consentimiento o con base en el interés legítimo de la empresa, en los términos del artículo 7, incisos I y IX, de la Ley nº 13.709/2018.",
          p3:
            "Eventualmente, podremos utilizar los datos para finalidades no previstas en nuestro <b>Aviso</b>, pero que se encuentran dentro de sus legítimas expectativas. Si necesitamos utilizar sus datos para otros fines que no cumplan con esta prerrogativa, solo procederemos con la autorización, que se solicitará previamente.",
          p4:
            "Asimismo, sus datos podrán ser utilizados para el ejercicio habitual de derechos y para el cumplimiento de obligaciones legales o reglamentarias, tal y como establece la Ley General de Protección de Datos.",
        },
        topic6: {
          title: "SOBRE EL ACCESO A SUS DATOS PERSONALES",
          p1:
            "Sin perjuicio de los casos de intercambio de datos, el acceso a su información personal estará restringido únicamente a los empleados de <b>NullBug</b> y, entre estos, únicamente a las personas que cuenten con autorizaciones internas específicas para este fin.",
          p2:
            "Si su información se inserta en acciones creadas en asociaciones, los socios identificados explícitamente también tendrán acceso a los datos. Salvo disposición legal en contrario, ningún dato personal podrá ser divulgado públicamente sin su previa y expresa autorización.",
          p3:
            "Además, <b>NullBug</b> realizará todos los esfuerzos razonables para garantizar la seguridad de nuestros sistemas y, en consecuencia, de sus datos personales.",
          p4:
            "Conservaremos sus datos personales e información únicamente hasta que sean necesarios o pertinentes para las finalidades descritas en nuestro <b>Aviso</b>, o en caso de plazos predeterminados por la Ley, o hasta que estos sean necesarios para el mantenimiento legítimo intereses de <b>NullBug</b>, en los términos de la Ley.",
        },
        topic7: {
          title: "COMPARTIR DATOS",
          p1:
            "<b>NullBug</b> puede compartir sus datos personales con socios, terceros, proveedores de servicios (incluidos los servidores de almacenamiento en la nube ubicados fuera de Brasil), según sea razonablemente necesario para los fines establecidos y para la correcta prestación de nuestros servicios.",
          p2:
            "Todos los datos, información y contenidos sobre usted podrán ser compartidos con terceros, cuando y en la medida estrictamente necesaria, observando el cumplimiento de la legislación y demás requisitos de seguridad.",
          p3:
            "<b>NullBug</b> se reserva el derecho de proporcionar sus datos e información sobre usted, incluidas sus interacciones, si así lo requiere la ley, un acto necesario para que <b>NullBug</b> cumpla con las leyes nacionales, o si lo autoriza expresamente.",
        },
        topic8: {
          title: "MEDIDAS DE SEGURIDAD",
          p1:
            "<b>NullBug</b> utilizará medidas técnicas y administrativas de seguridad para evitar accesos no autorizados y situaciones accidentales y/o ilícitas de manipulación, pérdida, destrucción y/o alteración de dichos datos.",
          p2:
            "Dichas medidas se mejoran y adaptan continuamente de acuerdo con la actualización de la tecnología aplicable, así como las mejores prácticas del mercado al respecto.",
        },
        topic9: {
          title: "ALTERACIÓN / ELIMINACIÓN DE DATOS PERSONALES",
          p1:
            "Puede optar por no recibir más correos electrónicos de <b>NullBug</b>, excepto en relación con los correos electrónicos imprescindibles para la continuidad de la prestación de nuestros servicios o incluso aquellos que, por obligación legal o reglamentaria, sea es necesario enviar a sus usuarios.",
          p2:
            "Para ello, habrá un enlace en nuestros correos electrónicos para solicitar la cancelación de su registro. Al hacer clic en este enlace, se dará de baja automáticamente de la lista de contactos.",
          p3:
            "Es importante mencionar que al llenar cualquier formulario o realizar cualquier tipo de registro, se caracterizará nuevamente la reinserción de su correo electrónico a la lista de contactos de <b>NullBug</b>. Por lo tanto, la solicitud de cancelación debe realizarse nuevamente si es de su interés.",
          p4:
            "Todos los datos recabados serán eliminados de nuestros servidores cuando usted lo solicite o, incluso, cuando estos ya no sean necesarios o pertinentes para que podamos ofrecerle Productos y Servicios, salvo que exista alguna otra razón para su mantenimiento, como una posible obligación legal. conservar los datos o la necesidad de conservarlos para proteger los derechos de <b>NullBug</b>.",
        },
        topic10: {
          title: "CAMBIOS AL AVISO DE PRIVACIDAD",
          p1:
            "Nuestro <b>Aviso</b> puede actualizarse. De esta forma, le recomendamos visitar periódicamente nuestra página para que esté al tanto de los cambios.",
        },
        topic11: {
          title: "RECLAMOS",
          p1:
            'También tiene derecho a solicitar a la Autoridad Nacional de Protección de Datos (ANPD), la autoridad responsable de garantizar el respeto de los derechos de los titulares de datos personales en Brasil y de supervisar el cumplimiento de la Ley General de Protección de Datos Personales (Ley n. 13.709/ 18 o LGPD). Acceda a él en: <a id="underlineLink" href="https://www.gov.br/anpd/pt-br">https://www.gov.br/anpd/pt-br</a> .',
        },
        topic12: {
          title: "DISPOSICIONES FINALES",
          p1:
            'Para obtener más información, visite nuestro <a id="underlineLink" href="/docs">Aviso de Privacidad</a> completo.',
          p2:
            "Este documento fue modificado por última vez el 03 de abril de 2023.",
        },
      },
      portal: {
        p1:
          "En primer lugar, le recomendamos que lea nuestras políticas relacionadas con la Privacidad y Protección de Datos Personales, ya que allí se encuentra la información más importante.",
        p2:
          "Sin embargo, con el fin de brindarle transparencia y facilidad, de conformidad con la Ley nº 13.709/18 - Ley General de Protección de Datos Personales (LGPD), para ejercer sus derechos como Titular de los Datos, <b>Nullbug</b> creó este Portal de Comunicación.",
        p3:
          'Por lo tanto, haga su solicitud directamente en este <a id="underlineLink" href="/docs/portal/form">formulario de solicitud</a>.',
        p4:
          "En ella deberá informar sus datos y enviar documentos que acrediten que la persona es efectivamente usted o una persona autorizada para actuar en su nombre, así como justificar su solicitud.",
        p5:
          "Al reenviar este mensaje y proporcionar los datos requeridos, el titular declara conocer y estar de acuerdo con nuestro <b>Aviso de Privacidad</b>, siendo consciente del tratamiento de sus datos con la finalidad de ejercer sus derechos.",
        p6:
          "Las solicitudes serán contestadas en un formato simplificado dentro de <b>01 (un) día hábil</b> contado a partir de la recepción de la solicitud, y, en caso de imposibilidad de adopción inmediata de la medida, responderemos dentro del plazo indicado anteriormente , informando que:",
        p7:
          "a) no somos el agente del tratamiento de los datos de que se trate, indicando, siempre que sea posible, cuál sería el agente responsable;<br /> b) señalar las razones de hecho o de derecho que impiden la adopción inmediata de la medida.",
        p8:
          "Si el Titular necesita una declaración clara y completa, indicando el origen de los datos, la falta de registro, los criterios utilizados y la finalidad del tratamiento, observando secretos comerciales e industriales, el plazo será de <b>15 (quince) días útiles</b>, desde la recepción de la solicitud.",
        p9: "¡Ejerce tus derechos!",
      },
      versionHistory: "Historial de versiones",
      months: {
        january: "Enero",
        february: "Febrero",
        march: "Marzo",
        april: "Abril",
        may: "Mayo",
        june: "Junio",
        july: "Julio",
        august: "Agosto",
        september: "Septiembre",
        october: "Octubre",
        november: "Noviembre",
        december: "Diciembre",
      },
      form: {
        title: "Formulario de Reclamación",
        p1:
          "Para ejercer sus derechos como Interesado, complete el siguiente formulario.",
        p2:
          "Como medida de seguridad, su solicitud solo será analizada si valida sus datos y demuestra que la persona que solicita información es usted o una persona autorizada para actuar en su nombre.",
        subject: "Sujeto",
        placeholder: "Seleccione un tema...",
        questions: "Preguntas",
        suggestions: "Sugerencias",
        name: "Nombre Completo",
        email: "E-mail",
        reqDate: "Fecha de solicitud",
        request: "Pedido",
        send: "Enviar petición",
        requestSended:
          "La solicitud se envió con éxito, en breve nos pondremos en contacto contigo por correo electrónico.",
      },
      errors: {
        errorRequired: "es necesario",
        errorUnexpected:
          "Se ha producido un error inesperado. Por favor, inténtalo de nuevo.",
        errorContact: "¡Nos pondremos en contacto con usted pronto!",
        errorRegistered: "¡Estás registrado!",
        errorNameProject: "Nombre del proyecto",
        errorName: "Nombre",
        errorEmail: "Email",
        errorSubject: "Sujeto",
        errorCPF: "CPF",
        errorRG: "DNI",
        errorReqDate: "Fecha de solicitud",
        errorRequest: "Pedido",
      },
    },
  },
}
