export const messages = {
  // HOME PAGE
  en: {
    translations: {
      titles: {
        tPrincipal:
          "> Design and digital products development without the hassle of traditional devs _",
        tExpertise: "> Expertise _",
        tOurClients: "< Our clients _>",
        tCases: "< Selected cases >",
        tTechnologies: "< Technologies we use >",
        tMoreThanCode: "> We deliver more than just a code _",
        tEndToEnd:
          "> From end-to-end in creation, to build the best digital products and services _",
        tAbout: "about",
        tTechPeoples: "> Technology made by people _",
        tNewsletter: "Newsletter",
      },
      messages: {
        startProject: "Start a project  >>",
        msgExpertise:
          "More than 20 years of experience in project development for major " +
          "players in the market has consolidated us to have in our portfolio " +
          "leading companies in their segments.",
        msgCases: "> We prefer to let our work speak for ourselves _",
        msgIsohub:
          "> An experience designed for the daily routine of a graduation agency _",
        msgSCSeg:
          "> Management at a click away. Residencial condos being automated and managed through the platform _",
        msgKitt:
          "> A tool used to automate tasks, managing daily assignments in a quick and organized way _",
        msgBierHeld:
          "> A complete sales force app for one of Brazil's largest breweries _",
        msgDiscover:
          "We identify the performance points in each scenario of the application, focusing on the user and not only on the code",
        msgDesign:
          "Before we start putting everything into code, we build the prototype. This way we can validate the idea quickly",
        msgDevelop: "With frequent deliveries, the application is being built",
        msgDeliver: "The final handover",
        msgAbout1:
          "Null Bug was born from the desire to offer design and development of digital products in a dynamic way.",
        msgAbout2:
          "Understanding the real needs of our clients, we end promises and focus on what will make the difference for you.",
        msgTechPeoples:
          "> We have a multidisciplinary team, with distinct specialties in order to cover all the needs of a digital " +
          "product development. From its beginning to its delivery and placement in the market, " +
          "we guarantee quality in the smallest details.",
        msgNewsletter:
          "Do you want to know more? Subscribe to our newsletter to learn about strategies to improve management, " +
          "discover the best practices and stay on top with our content about technology.",
      },
      buttons: {
        startProject: "Start a project",
        buttonSign: "SUBSCRIBE",
      },
      tags: {
        tagDiscover: " Discover",
        tagDraw: "Design",
        tagDevelop: "Develop",
        tagDeliver: "Deliver",
        tagTechnology: "Technology",
        tagDesign: "Design",
        tagBusiness: "Business",
        tagUx: "UX",
        tagProgramming: "Programming",
        tagFace: "Facial Recognition",
      },
      address: {
        addressStreet: "Palmas street,  1943,",
        addressBuilding: "Solaris building,",
        addressRoom: "Room 801 and 802, 8th floor - Downtown",
        addressAbbrev: "<city/State>",
        addressCity: "Francisco Beltrão/PR - Brazil",
      },
      cookiesMsg: {
        cksTitle: "COOKIE DISCLAIMER",
        cksContent:
          'We inform you that our website uses cookies and similar technologies to improve your experience. By continuing to browse, we assume that you are aware of our Cookie Disclaimer and that you agree with our Policy. Therefore, if you click accept, you will have access to all the features of the site. If you decline, cookies that are not strictly necessary will be disabled. If you prefer, you can customize your choice by defining which cookies you want to authorize by clicking on "Manage cookies". To learn more, visit our',
        link: "Cookie Policy.",
        cksManage: "Manage cookies",
        cksReject: "Reject cookies",
        cksAccept: "Accept cookies",
      },
      managementCookiesMsg: {
        title: "MANAGE COOKIES",
        subtitle: "For more information, please visit our",
        link: "Cookie Policy.",
        necessaryCookiesTitle: "Strictly necessary cookies",
        necessaryCookies:
          "They are essential cookies that allow navigation in our applications and access to all features and functionalities, which cannot be deactivated in our systems. You can block these cookies directly in your browser, in which case our services may perform poorly or not at all.",
        performanceCookiesTitle: "Performance cookies",
        performanceCookies:
          "These cookies aim to improve the performance of the website by collecting anonymized data on navigation and use of available resources, which may be collected by third parties. You do not need to allow the collection of these cookies, however this data cannot be used to improve the website.",
        advertisingCookiesTitle: "Advertising cookies",
        advertisingCookies:
          "These are cookies set by trusted third parties that are used, for example, for advertising and marketing, targeting personalized ads according to your interest. You can also disable them.",
        responsibility:
          "NullBug has no control over which third-party cookies are set. Some third-party cookies that can be found when accessing our website have the domain of Google and Facebook, and are more detailed in our Policy.",
        configTitle: "Browser cookie settings",
        configSubtitle:
          "You can disable them by changing your browser settings",
        save: "Save",
      },
      // STARTING A PROJECT PAGE
      startingProject: {
        strSuccess: "Let's turn your idea into success",
        strCompany: "What is your company name or project?*",
        strName: "What is your name?*",
        strEmail: "What is your email address?*",
        strPhone: "What is your phone number?",
        strCanDo: "What can Null Bug do for you?",
        strProduct: "Create an amazing product from scratch",
        strGreater: "Make my existing product even greater",
        strServers: "Help with server maintenance",
        strOther: "Other",
        strTellUs: "Could you tell us more about it?",
        strBudget: "What is your budget?",
        strLessThan: "Less than R$ 25.000,00",
        strOrMore: "R$ 100.000,00 or more",
        strNotDefined: "Undefined",
        strMeetUs: "How did you get to know us?",
        strSend: "SEND",
        strRegister: "You have been registered. We will contact you shortly. ",
        strThanks: "Thank you!",
        strContact: "Contact",
        strPrivacy: "Privacy",
        strDocs: "Terms, Disclaimers and Privacy Policy",
        strNamePlaceholder: "Your name",
        strEmailPlaceholder: "Your email address",
        strSendButton: "SEND",
      },
      footer: {
        footerRights: "All rights reserved.",
        footerCompany:
          "NULLBUG SOFTWARE DEVELOPMENT AND COMPUTER TRAINING LTDA.",
      },
      docs: {
        dataProtection: "How We Protect Your Data",
        portal: "Data Subject Communication Portal",
        privacy: "Privacy Disclaimer",
        termsAndConditions: "Terms and Conditions of Use",
        cookiePolicy: "Cookie Policy",
      },
      dataProtection: {
        p1:
          'The purpose of writing this document as it is presented is to demonstrate to you, the data subject, in an accessible and summarized way, the main points of our Privacy Disclaimer. To learn more, visit our full <a id="underlineLink" href="/docs">Disclaimer</a>.',
        p2: "Find out how we use and protect your personal data.",
        p3:
          "In this sense, we aim to clarify what information is collected from users of our websites and respective products and services, and how this data is treated.",
        p4:
          "In case of disagreement, please do not share your information to use our services and products, nor use our website. However, we kindly ask you to clarify the reason for the disagreement, so that we can analyze your suggestions and, eventually, revise our <b>Disclaimer</b>.",
        topic1: {
          title: "WHAT IS LGPD?",
          p1:
            "The <b>General Data Protection Law (LGPD)</b> provides for the processing of personal data, in order to ensure your rights and guarantee the responsible and protected use of your data.",
        },
        topic2: {
          title: "WHAT ARE YOUR RIGHTS AND HOW CAN YOU EXERCISE THEM?",
          p1:
            "According to art. 18 of the General Data Protection Law, the data subject may request information and measures in relation to his personal data.",
          p2:
            'You can exercise your rights whenever you want through our <a id="underlineLink" href="/docs/portal">Data Subject Communication Portal</a>.<br/><br/>',
          p3: "The <b>rights</b> of the Holders of Personal Data are:",
          laws: {
            1: "confirmation of the existence of treatment;",
            2: "data access;",
            3: "correction of incomplete, inaccurate or outdated data;",
            4: "anonymization, blocking or deletion of unnecessary, excessive data;",
            5: "portability of data to another service or product provider, upon express request, in accordance with the regulations of the national authority, observing commercial and industrial secrets;",
            6: "deletion of personal data processed with the consent of the holder;",
            7: "information from public and private entities with which the controller carried out shared use of data;",
            8: "information about the possibility of not giving consent and about the consequences of the refusal;",
            9: "revocation of consent; it is",
            10: "review of automated decisions.<br/><br/>",
          },
          p4:
            "It is important to note that your rights are not absolute. In some situations, we may retain information to comply with our legal and regulatory obligations, or for the regular exercise of our rights. In this case, you will be informed whenever necessary.",
        },
        topic3: {
          title: "PRECAUTIONS YOU SHOULD TAKE",
          p1:
            "We warn you that we will not send electronic messages with confirmations through links and files that can be executed on your devices, so never send your data through unofficial channels. Also, be careful with your personal passwords and the sites you access.",
        },
        topic4: {
          title: "THE INFORMATION WE COLLECT",
          content: {
            title1: "<b>Information provided by you</b>",
            p1:
              "From the information provided by you by completing the forms available on our websites and applications, <b>NullBug</b> will collect those of personal identification such as, for example, name, email and telephone number. Eventually, the request for other information may be made through direct contact by <b>NullBug</b> with the users, in person or via e-mail or telephone, for the execution of an eventual contract.",
            title2: "<b>Website navigation information</b>",
            p2: `When you access our websites and applications, cookies may be installed on your browser. For more information, please visit our <a id="underlineLink" href="/docs">Cookie Policy</a>.`,
            title3: "<b>Contact history</b>",
            p3:
              "<b>NullBug</b> stores information about all contacts already made with our users, such as personal interactions, via telephone or via email.",
            title4:
              "<b>Data generated by using our services or requesting our products</b>",
            p4:
              "If you use any of our services, request our products, or even in cases where you are a customer of our customers and the provision of service involves your data, <b>NullBug</b> may collect information necessary for the execution of the contract for the provision of Service or supply of the Product. In this case, contact data such as your full name, CPF, company name, email, telephone and address may be collected.",
          },
        },
        topic5: {
          title: "WHAT WE USE YOUR INFORMATION FOR",
          p1:
            "The personal data we collect are used for the execution of Service Provision or Product Supply contracts, always respecting your privacy. Therefore, your data will only be used for the purposes described here and authorized by you.",
          p2:
            "We may use your data to create profiles based on your behavior data and check what your interests are, to classify and qualify according to this profile, and to create dynamic lists to carry out targeted marketing actions, but in this case, only when with your consent or based on the legitimate interest of the company, pursuant to article 7, items I and IX, of Law No. 13,709/2018.",
          p3:
            "Eventually, we may use data for purposes not foreseen in our <b>Disclaimer</b>, but which are within your legitimate expectations. If we need to use your data for other purposes that do not comply with this prerogative, we will only proceed with authorization, which will be requested in advance.",
          p4:
            "In addition, your data may be used for the regular exercise of rights and for compliance with legal or regulatory obligations, as provided for in the General Data Protection Law.",
        },
        topic6: {
          title: "ABOUT ACCESS TO YOUR PERSONAL DATA",
          p1:
            "Without prejudice to cases of data sharing, access to your personal information will be restricted only to <b>NullBug</b> employees and among these, only people with specific internal authorizations for this purpose.",
          p2:
            "If your information is inserted in actions created in partnerships, the explicitly identified partners will also have access to the data. Unless otherwise provided by law, no personal data may be publicly disclosed without your prior and express authorization.",
          p3:
            "Furthermore, <b>NullBug</b> will undertake all reasonable efforts to ensure the security of our systems and, consequently, of your personal data.",
          p4:
            "We will keep your personal data and information only until when they are necessary or relevant for the purposes described in our <b>Disclaimer</b>, or in case of periods predetermined by Law, or until when these are necessary for the maintenance legitimate interests of <b>NullBug</b>, under the terms of the Law.",
        },
        topic7: {
          title: "DATA SHARING",
          p1:
            "<b>NullBug</b> may share your personal data with partners, third parties, service providers (including cloud storage servers located outside Brazil), as reasonably necessary for the established purposes and for the proper provision of services. our services.",
          p2:
            "All data, information and content about you may be shared with third parties, when and to the extent strictly necessary, observing compliance with legislation and other security requirements.",
          p3:
            "<b>NullBug</b> reserves the right to provide your data and information about you, including your interactions, if legally required to do so, a necessary act for <b>NullBug</b> to comply with national laws, or if you expressly authorize it.",
        },
        topic8: {
          title: "SECURITY MEASURES",
          p1:
            "<b>NullBug</b> will use technical and administrative security measures in order to avoid unauthorized access and accidental and/or illegal situations of manipulation, loss, destruction and/or alteration of said data.",
          p2:
            "Such measures are continuously improved and adapted according to the update of the applicable technology, as well as the best market practices in this regard.",
        },
        topic9: {
          title: "ALTERATION / DELETION OF PERSONAL DATA",
          p1:
            "You can choose not to receive more e-mails from <b>NullBug</b>, except in relation to essential e-mails for the continuity of the provision of our services or even those that, due to legal or regulatory obligation, it is necessary send to your users.",
          p2:
            "For this, there will be a link in our emails to request the cancellation of your registration. By clicking on this link you will be automatically unsubscribed from the contact list.",
          p3:
            "It is important to mention that when filling out any form or carrying out any type of registration, the reinsertion of your e-mail to the <b>NullBug</b> contact list will again be characterized. Therefore, the cancellation request must be made again if it is in your interest.",
          p4:
            "All data collected will be deleted from our servers when you request it or, even, when these are no longer necessary or relevant for us to offer you Product and Services, unless there is any other reason for their maintenance, such as a possible legal obligation to retain data. data or the need to preserve them to protect the rights of <b>NullBug</b>.",
        },
        topic10: {
          title: "CHANGES TO THE PRIVACY DISCLAIMER",
          p1:
            "Our <b>Disclaimer</b> may be updated. In this way, we recommend periodically visiting our page so that you are aware of the changes.",
        },
        topic11: {
          title: "CLAIMS",
          p1:
            'You also have the right to petition before the National Data Protection Authority (ANPD), the authority responsible for ensuring respect for the rights of personal data holders in Brazil and overseeing compliance with the General Law for the Protection of Personal Data (Law n. 13,709/18 or LGPD). Access it at: <a id="underlineLink" href="https://www.gov.br/anpd/pt-br">https://www.gov.br/anpd/pt-br</a>.',
        },
        topic12: {
          title: "FINAL DISPOSITIONS",
          p1:
            'To learn more, please visit our full <a id="underlineLink" href="/docs">Privacy Disclaimer</a>.',
          p2: "This document was last modified on April 03, 2023.",
        },
      },
      portal: {
        p1:
          "First, we recommend that you read our policies related to Privacy and Personal Data Protection, as the most important information is there.",
        p2:
          "However, in order to bring transparency and ease for you, in accordance with Law nº 13.709/18 - General Law for the Protection of Personal Data (LGPD), to exercise your rights as a Data Holder, <b>Nullbug</b> created this Communication Portal.",
        p3:
          'Therefore, make your request directly on this <a id="underlineLink" href="/docs/portal/form">application form</a>.',
        p4:
          "In it, you must inform your data and send documents that prove that the person is in fact you or a person authorized to act on your behalf, as well as justifying your request.",
        p5:
          "By forwarding this message and providing the required data, the holder declares to be aware of and agree with our <b>Privacy Notice</b>, being aware of the processing of their data for the purpose of exercising rights.",
        p6:
          "Requests will be answered in a simplified format within <b>01 (one) business day</b> from the receipt of the request, and, in case of impossibility of immediate adoption of the measure, we will respond within the period indicated above, informing that:",
        p7:
          "a) we are not the processing agent of the data in question, indicating, whenever possible, which would be the responsible agent;<br /> b) indicate the factual or legal reasons that prevent the immediate adoption of the measure.",
        p8:
          "If the Holder needs a clear and complete statement, indicating the origin of the data, the lack of registration, the criteria used and the purpose of the treatment, observing commercial and industrial secrets, the period will be <b>15 (fifteen) days useful</b>, from the receipt of the request.",
        p9: "Exercise your rights!",
      },
      versionHistory: "Version history",
      months: {
        january: "January",
        february: "February",
        march: "March",
        april: "April",
        may: "May",
        june: "June",
        july: "July",
        august: "August",
        september: "September",
        october: "October",
        november: "November",
        december: "December",
      },
      form: {
        title: "Claim Form",
        p1:
          "To exercise your rights as a Data Subject, complete the form below.",
        p2:
          "As a security measure, your request will only be analyzed if you validate your data and prove that the person requesting information is in fact you or a person authorized to act on your behalf.",
        subject: "Subject",
        placeholder: "Select a subject...",
        questions: "Questions",
        suggestions: "Suggestions",
        name: "Full Name",
        email: "E-mail",
        reqDate: "Request Date",
        request: "Request",
        send: "Send request",
        requestSended:
          "The request was sent successfully, we will contact you via email shortly.",
      },
      errors: {
        errorRequired: "is required",
        errorUnexpected: "An unexpected error has occurred. Please, try again.",
        errorContact: "We will get in touch soon!",
        errorRegistered: "You’ve been registered!",
        errorNameProject: "Project name",
        errorName: "Name",
        errorEmail: "Email",
        errorSubject: "Subject",
        errorCPF: "Taxpayer Registry",
        errorRG: "Identification Card",
        errorReqDate: "Request Date",
        errorRequest: "Request",
      },
    },
  },
}
