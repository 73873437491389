import React, { useState } from "react"

import * as styles from "./dropdown.module.css"
import ArrowDown from "../../assets/svg/arrow-down.svg"
import ArrowUp from "../../assets/svg/arrow-up.svg"

export function Dropdown({ value, options, onChange }) {
  const [open, setOpen] = useState(false)

  const handleInputClick = e => {
    e.stopPropagation()

    setOpen(open => !open)
  }

  const optionSelected = options.find(item => item.value === value)

  return (
    <div className={styles.content}>
      <div className={styles.input} onClick={handleInputClick}>
        <div className={styles.placeholder}>{optionSelected?.label}</div>
        <div className={styles.icon_container}>
          {open ? (
            <ArrowUp width={20} height={20} />
          ) : (
            <ArrowDown width={20} height={20} />
          )}
        </div>
      </div>
      {open && (
        <div className={styles.menu}>
          {options.map(item => (
            <div
              key={item.value}
              className={styles.option}
              onClick={() => onChange(item)}
            >
              {item.label}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
