// extracted by mini-css-extract-plugin
export var container = "footer-module--container--1Jdmb";
export var contato_title = "footer-module--contato_title--1q25E";
export var input_field = "footer-module--input_field--1qPMv";
export var logo = "footer-module--logo--3EjtC";
export var endSection = "footer-module--endSection--2nzsR";
export var copyright = "footer-module--copyright--jAIYC";
export var links = "footer-module--links--1Kz7s";
export var link = "footer-module--link--2DGtx";
export var title = "footer-module--title--3KuXD";
export var contact_form = "footer-module--contact_form--3gdSF";
export var font_ocr = "footer-module--font_ocr--3fkxm";
export var svg_social_icon = "footer-module--svg_social_icon--2M_xX";