export const rejectAdvertisingCookies = () => {
  document.cookie = "_fbp= ; expires=Thu, 18 Dec 2013 12:00:00 UTC"
  document.cookie =
    "fr= ; domain=.facebook.com; expires=Thu, 18 Dec 2013 12:00:00 UTC"
  document.cookie =
    "sb= ; domain=.facebook.com; expires=Thu, 18 Dec 2013 12:00:00 UTC"
  document.cookie =
    "c_user= ; domain=.facebook.com; expires=Thu, 18 Dec 2013 12:00:00 UTC"
  document.cookie =
    "xs= ; domain=.facebook.com; expires=Thu, 18 Dec 2013 12:00:00 UTC"
}
