import * as React from "react"

import * as styles from './grid.module.css'

const Grid = ({children}) => {

  return (
    <div className={styles.container}>
      {children}
    </div>
  )
}

export default Grid
