import * as React from "react"

import * as styles from './big.module.css'

import NoBoringSmile from '../../assets/svg/no_boring_with_smile.svg'

const BigNoBoring = () => {

  return (
    <div className={styles.no_boring}>
      <NoBoringSmile className={styles.svg} />
    </div>
  )
}

export default BigNoBoring
