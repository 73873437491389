import React from "react"

import "./cookies.css"
import { i18n } from "../../translate/i18n"
import { rejectAllCookies } from "../../utils/rejectAllCookies"
import CookiePolicy from "../../docs/CookiePolicy.pdf"

const Cookies = ({
  setDisplayDisclaimer,
  setOpenManagementModal,
  setPerformanceCookies,
  setAdvertisingCookies,
}) => (
  <div>
    <section className="container">
      <div className="subcontainer">
        <p className="title">{i18n.t("cookiesMsg.cksTitle")}</p>
        <p>
          {i18n.t("cookiesMsg.cksContent")}{" "}
          <a id="underlineLink" href={CookiePolicy}>
            {i18n.t("cookiesMsg.link")}
          </a>
        </p>
        <div className="buttons_container">
          <button
            className="button"
            onClick={() =>
              setOpenManagementModal(
                openManagementModal => !openManagementModal
              )
            }
          >
            {i18n.t("cookiesMsg.cksManage")}
          </button>
          <button
            className="button"
            onClick={() => {
              setDisplayDisclaimer(false)
              setPerformanceCookies(false)
              setAdvertisingCookies(false)
              rejectAllCookies()
              localStorage.setItem("cookiePermissions", "allRejected")
            }}
          >
            {i18n.t("cookiesMsg.cksReject")}
          </button>
          <button
            className="button"
            onClick={() => {
              setDisplayDisclaimer(false)
              setPerformanceCookies(true)
              setAdvertisingCookies(true)
              localStorage.setItem("cookiePermissions", "allAccepted")
            }}
          >
            {i18n.t("cookiesMsg.cksAccept")}
          </button>
        </div>
      </div>
    </section>
  </div>
)

export default Cookies
