export const messages = {
  //HOME PAGE
  pt: {
    translations: {
      titles: {
        tPrincipal:
          " > Design e desenvolvimento de produtos digitais " +
          "sem as chatices dos devs tradicionais _",
        tExpertise: "> Expertise _",
        tOurClients: "< Nossos clientes _ >",
        tCases: "< Cases selecionados >",
        tTechnologies: "< Tecnologias que utilizamos >",
        tMoreThanCode: "> Entregamos mais do que apenas código _",
        tEndToEnd:
          "> De ponta a ponta na criação, para construir os melhores produtos e serviços digitais _",
        tAbout: "about",
        tTechPeoples: "> Tecnologia feita por pessoas _",
        tNewsletter: "Newsletter",
      },
      messages: {
        startProject: "Começar um projeto >>",
        msgExpertise:
          "Os mais de 20 anos de experiência em desenvolvimento em projetos " +
          "para grandes players do mercado nos consolidam para termos em nosso portfólio " +
          "empresas referência em seus segmentos.",
        msgCases: "> Gostamos de deixar que nossos trabalhos falem por nós _",
        msgIsohub:
          "> Uma experiência pensada para toda a rotina de uma agência de formaturas _",
        msgSCSeg:
          "> Gestão ao alcance de um click, condomínios automatizados e geridos através da plataforma _",
        msgKitt:
          "> Uma ferramenta para automatizar tarefas, gerindo tarefas de rotina de uma forma rápida e organizada _",
        msgBierHeld:
          "> Um app completo de força de vendas para uma das maiores cervejarias do Brasil _",
        msgDiscover:
          " Identificamos os pontos de atuação em cada cenário da aplicação, com foco no usuário, não somente no código ",
        msgDesign:
          "Antes de começar a colocar tudo em código, montamos o protótipo. Assim conseguimos validar a ideia com agilidade",
        msgDevelop: "Com entregas frequentes, a aplicação vai sendo construída",
        msgDeliver: "A entrega final",
        msgAbout1:
          "A Null Bug nasceu do desejo de oferecer design e desenvolvimento de produtos digitais de uma maneira dinâmica.",
        msgAbout2:
          "Entendendo as reais necessidades dos clientes, nós acabamos com as promessas e focamos naquilo que vai fazer a diferença para você.",
        msgTechPeoples:
          "> Temos uma equipe multidisciplinar, com especialidades distintas a fim de cobrir todas as necessidades do desenvolvimento de produtos digitais. " +
          "Do seu início até a sua entrega e colocação no mercado, garantimos a qualidade nos mínimos detalhes.",
        msgNewsletter:
          "Quer saber mais? Assine nossa newsletter, conheça estratégias para melhoria da gestão, descubra as melhores práticas e fique por dentro " +
          "com nossos conteúdos sobre tecnologia.",
      },
      buttons: {
        startProject: "Começar um projeto",
        buttonSign: "ASSINAR",
      },

      tags: {
        tagDiscover: "Descobrir",
        tagDraw: "Desenhar",
        tagDevelop: "Desenvolver",
        tagDeliver: "Entregar",

        tagTechnology: "Tecnologia",
        tagDesign: "Design",
        tagBusiness: "Negócios",
        tagUx: "UX",
        tagProgramming: "Programação",
        tagFace: "Reconhecimento facial",
      },

      address: {
        addressStreet: "Rua Palmas, 1943,",
        addressBuilding: "Edifício Solaris,",
        addressRoom: "Sala 801 e 802, 8° andar – Centro",
        addressAbbrev: "<cid/UF>",
        addressCity: "Francisco Beltrão – PR,",
      },

      cookiesMsg: {
        cksTitle: "AVISO DE COOKIES",
        cksContent:
          'Informamos que nosso site utiliza cookies e tecnologias semelhantes para melhorar sua experiência. Ao continuar navegando, consideramos que você está ciente de nosso Aviso de Cookies e que concorda com nossa Política. Sendo assim, se clicar em aceitar, você terá acesso a todas as funcionalidades do site. Se rejeitar, os cookies que não forem estritamente necessários serão desativados. Se preferir, você pode personalizar sua escolha definindo quais cookies deseja autorizar, clicando em "Gerenciar cookies". Para saber mais, acesse nossa',
        link: "Política de Cookies.",
        cksManage: "Gerenciar cookies",
        cksReject: "Rejeitar cookies",
        cksAccept: "Aceitar cookies",
      },
      managementCookiesMsg: {
        title: "GERENCIAR COOKIES",
        subtitle: "Para mais informações, acesse nossa",
        link: "Política de Cookies.",
        necessaryCookiesTitle: "Cookies estritamente necessários",
        necessaryCookies:
          "São cookies essenciais que possibilitam a navegação em nossas aplicações e o acesso a todos os recursos e funcionalidades, que não podem ser desativados em nossos sistemas. Você pode bloquear esses cookies diretamente no seu navegador, mas nesse caso nossos serviços podem apresentar mal desempenho ou não funcionar.",
        performanceCookiesTitle: "Cookies de desempenho",
        performanceCookies:
          "Esses cookies visam a melhoria do desempenho do site por meio da coleta de dados anonimizados sobre navegação e uso dos recursos disponibilizados, podendo ser coletados por terceiros. Você não precisa permitir a coleta desses cookies, no entanto, esses dados não poderão ser usados para melhoria do site.",
        advertisingCookiesTitle: "Cookies de publicidade",
        advertisingCookies:
          "São cookies definidos por terceiros confiáveis que são utilizados, por exemplo, para publicidade e marketing, direcionando anúncios personalizados conforme seu interesse. Você também pode desativá-los.",
        responsibility:
          "A NullBug não tem controle sobre quais cookies de terceiros serão ativados. Alguns cookies de terceiros que podem ser encontrados ao acessar nosso site possuem o domínio do Google e Facebook, e estão mais detalhados na nossa Política.",
        configTitle: "Configurações de cookies no navegador",
        configSubtitle:
          "Você pode desabilitá-los alterando as configurações do seu navegador",
        save: "Salvar",
      },
      // STARTING A PROJECT PAGE
      startingProject: {
        strSuccess: "Vamos transformar sua ideia em sucesso",
        strCompany: "Qual o nome da sua empresa ou seu projeto?*",
        strName: "Qual o seu nome?*",
        strEmail: "Qual seu e-mail?*",
        strPhone: "Qual o número do seu telefone?",
        strCanDo: "O que a Null Bug pode fazer por você?",
        strProduct: "Criar um produto incrível, partindo do zero",
        strGreater: "Tornar meu produto existente ainda maior",
        strServers: "Ajuda com manutenção de servidores",
        strOther: "Outro",
        strTellUs: "Você poderia nos contar melhor sobre isso?",
        strBudget: "Qual é seu orçamento?",
        strLessThan: "Menos que R$ 25.000,00",
        strOrMore: "R$ 100.000,00 ou mais",
        strNotDefined: "Não definido",
        strMeetUs: "Como você nos conheceu?",
        strSend: "ENVIAR",
        strRegister: "Você foi cadastrado. Logo entraremos em contato.",
        strThanks: "Obrigado!",
        strContact: "Contato",
        strPrivacy: "Privacidade",
        strDocs: "Termos, Avisos e Políticas de Privacidade",
        strNamePlaceholder: "Seu nome",
        strEmailPlaceholder: "Seu e-mail",
        strSendButton: "ENVIAR",
      },
      footer: {
        footerRights: "Todos os direitos reservados.",
        footerCompany:
          "NULLBUG DESENVOLVIMENTO DE PROGRAMA DE COMPUTADOR E TREINAMENTO DE INFORMATICA LTDA.",
      },
      docs: {
        portal: "Portal de Comunicação do Titular dos Dados",
        dataProtection: "Como Protegemos seus Dados",
        privacy: "Aviso de Privacidade",
        termsAndConditions: "Termos e Condições de Uso",
        cookiePolicy: "Política de Cookies",
      },
      dataProtection: {
        p1:
          'A redação deste documento da maneira como é apresentada tem por objetivo demonstrar à você, titular de dados, de forma acessível e resumida, os principais pontos de nosso Aviso de Privacidade. Para saber mais, acesse nosso <a id="underlineLink" href="/docs">Aviso</a> completo.',
        p2: "Saiba como usamos e protegemos os seus dados pessoais.",
        p3:
          "Nesse sentido, temos como objetivo esclarecer quais informações são coletadas dos usuários de nossos websites e respectivos produtos e serviços, e de que forma esses dados são tratados.",
        p4:
          "Em caso de discordância, por favor, não compartilhe suas informações para utilização de nossos serviços e produtos, e nem utilize nosso website.  No entanto, solicitamos a gentileza de que nos esclareça o porquê da discordância, para que possamos analisar suas sugestões e, eventualmente, revisar nosso <b>Aviso</b>.",
        topic1: {
          title: "O QUE É LGPD?",
          p1:
            "A <b>Lei Geral de Proteção de Dados (LGPD)</b> dispõe sobre o tratamento de dados pessoais, visando assegurar seus direitos e garantir o uso responsável e protegido de seus dados.",
        },
        topic2: {
          title: "QUAIS SÃO OS SEUS DIREITOS E COMO VOCÊ PODE EXERCÊ-LOS?",
          p1:
            "Segundo o art. 18 da Lei Geral de Proteção de Dados, o titular dos dados pode requisitar informações e medidas em relação aos seus dados pessoais.",
          p2:
            'Você pode exercer seus direitos sempre que desejar por meio do nosso <a id="underlineLink" href="/docs/portal">Portal de Comunicação do Titular dos Dados</a>.<br/><br/>',
          p3: "São <b>direitos</b> dos Titulares de Dados Pessoais:",
          laws: {
            1: "confirmação da existência de tratamento;",
            2: "acesso aos dados;",
            3: "correção de dados incompletos, inexatos ou desatualizados;",
            4: "anonimização, bloqueio ou eliminação de dados desnecessários, excessivos;",
            5: "portabilidade dos dados a outro fornecedor de serviço ou produto, mediante requisição expressa, conforme a regulamentação da autoridade nacional, observados os segredos comercial e industrial;",
            6: "eliminação dos dados pessoais tratados com o consentimento do titular;",
            7: "informação das entidades públicas e privadas com as quais o controlador realizou uso compartilhado de dados;",
            8: "informação sobre a possibilidade de não fornecer consentimento e sobre as consequências da negativa;",
            9: "revogação do consentimento; e",
            10: "revisão de decisões automatizadas.<br/><br/>",
          },
          p4:
            "É importante ressaltar que seus direitos não são absolutos. Em algumas situações, podemos reter informações para o cumprimento de nossas obrigações legais e regulatórias ou, ainda, para o exercício regular dos nossos direitos. Neste caso, você será informado sempre que necessário.",
        },
        topic3: {
          title: "CUIDADOS QUE VOCÊ DEVE TOMAR",
          p1:
            "Avisamos que nós não enviaremos mensagens eletrônicas com confirmações por meio de links e arquivos que possam ser executados em seus dispositivos, portanto, nunca envie seus dados por meio de canais não oficiais. Ainda, tenha cuidado com as suas senhas pessoais e com os sites que acessa.",
        },
        topic4: {
          title: "DAS INFORMAÇÕES QUE COLETAMOS",
          content: {
            title1: "<b>Informações fornecidas por você</b>",
            p1:
              "Das informações fornecidas por você com o preenchimento dos formulários disponíveis em nossos websites e aplicativos, a <b>NullBug</b> coletará aquelas de identificação pessoal como, por exemplo, nome, e-mail e número de telefone. Eventualmente, a solicitação de outras informações poderá ser realizada por contato direto da <b>NullBug</b> com os usuários, presencialmente ou via e-mail ou telefone, para a realização de eventual contrato.",
            title2: "<b>Informações de navegação no website</b>",
            p2: `Quando você acessar nossos websites e aplicativos, poderão ser instalados cookies no seu navegador. Para mais informações, acesse nossa <a id="underlineLink" href="/docs">Política de Cookies</a>.`,
            title3: "<b>Histórico de contato</b>",
            p3:
              "A <b>NullBug</b> armazena informações a respeito de todos os contatos já realizados com nossos usuários, como interações pessoais, via telefone ou via e-mail.",
            title4:
              "<b>Dados gerados pela utilização de nossos serviços ou solicitação de nossos produtos</b>",
            p4:
              "Se você utilizar algum de nossos serviços, solicitar nossos produtos, ou ainda nos casos em que você é cliente dos nossos clientes e a prestação de serviço envolver seus dados, a <b>NullBug</b>  poderá coletar informações necessárias para a execução do contrato de prestação de Serviço ou fornecimento do Produto. Nesse caso, poderão ser coletados dados de contato como seu nome completo, CPF, nome da empresa, e-mail, telefone e endereço.",
          },
        },
        topic5: {
          title: "PARA QUE USAMOS SUAS INFORMAÇÕES",
          p1:
            "Os dados pessoais que coletamos são utilizados para a execução dos contratos de Prestação de Serviços ou Fornecimento de Produtos, sempre respeitando sua privacidade. Sendo assim, seus dados somente serão utilizados para os fins aqui descritos e autorizados por você.",
          p2:
            "Podemos utilizar seus dados para criar perfis com base nos dados de seu comportamento e verificar quais são seus interesses, para classificar e qualificar de acordo com esse perfil, e para criar listas dinâmicas para execução de ações de marketing direcionadas, mas nesse caso, apenas quando houver o seu consentimento ou baseado no legítimo interesse da empresa, nos termos do artigo 7º, incisos I e IX, da Lei nº 13.709/2018.",
          p3:
            "Eventualmente, poderemos utilizar dados para finalidades não previstas em nosso <b>Aviso</b>, mas que estejam dentro das suas legítimas expectativas. Se necessitarmos utilizar seus dados para outras finalidades que não cumpram com essa prerrogativa, procederemos apenas mediante autorização, que será solicitada previamente.",
          p4:
            "Além disso, seus dados poderão ser utilizados para o exercício regular de direitos e para o cumprimento de obrigação legal ou regulatória, conforme disposto na Lei Geral de Proteção de Dados.",
        },
        topic6: {
          title: "SOBRE O ACESSO AOS SEUS DADOS PESSOAIS",
          p1:
            "Sem prejuízo dos casos de compartilhamento de dados, o acesso às suas informações pessoais estarão restritos apenas aos colaboradores da <b>NullBug</b>  e dentre estes, somente as pessoas com as autorizações internas específicas para tanto.",
          p2:
            "Caso a inserção de suas informações se dê em ações criadas em parcerias, os parceiros explicitamente identificados também terão acesso aos dados. Exceto se de outra forma for previsto em Lei, nenhum dado pessoal poderá ser divulgado publicamente sem a sua prévia e expressa autorização.",
          p3:
            "Ademais, a <b>NullBug</b>  empreenderá todos os esforços razoáveis para garantir a segurança dos nossos sistemas e, consequentemente, dos seus dados pessoais.",
          p4:
            "Manteremos os seus dados pessoais e informações somente até quando estas forem necessárias ou relevantes para as finalidades descritas em nosso <b>Aviso</b>, ou em caso de períodos pré-determinados por Lei, ou até quando estas forem necessárias para a manutenção de interesses legítimos da <b>NullBug</b>, nos termos da Lei.",
        },
        topic7: {
          title: "COMPARTILHAMENTO DE DADOS",
          p1:
            "A <b>NullBug</b> poderá compartilhar os seus dados pessoais com parceiros, terceiros, fornecedores de serviços (incluindo servidores de armazenamento em nuvem localizados fora do Brasil), conforme for razoavelmente necessário para os propósitos estabelecidos e para a devida prestação de nossos serviços.",
          p2:
            "Todos os dados, informações e conteúdos sobre você podem ser compartilhados com terceiros, quando e na medida do estritamente necessário, observando o cumprimento da legislação e os demais requisitos de segurança.",
          p3:
            "A <b>NullBug</b> se reserva no direito de fornecer seus dados e informações sobre você, incluindo interações suas, caso seja requisitado judicialmente para tanto, ato necessário para que a <b>NullBug</b> esteja em conformidade com as leis nacionais, ou caso você autorize expressamente.",
        },
        topic8: {
          title: "MEDIDAS DE SEGURANÇA",
          p1:
            "A <b>NullBug</b> utilizará medidas de segurança técnicas e administrativas de modo a evitar acessos não autorizados e situações acidentais e/ou ilícitas de manipulação, perda, destruição e/ou alteração dos referidos dados.",
          p2:
            "Tais medidas são melhoradas e adaptadas continuamente conforme a atualização da tecnologia aplicável, bem como são observadas as melhores práticas de mercado neste sentido.",
        },
        topic9: {
          title: "ALTERAÇÃO/EXCLUSÃO DE DADOS PESSOAIS",
          p1:
            "Você pode optar por não receber mais e-mails da <b>NullBug</b>, exceto em relação aos e-mails essenciais para a continuidade da prestação de nossos serviços ou mesmo aqueles que, por obrigação legal ou regulatória, necessite-se enviar a seus usuários.",
          p2:
            "Para isso, haverá em nossos e-mails um link para solicitar o cancelamento do seu cadastro. Ao clicar nesse link você será automaticamente descadastrado da lista de contatos.",
          p3:
            "É importante mencionar que ao preencher qualquer formulário ou realizar qualquer tipo de cadastro ficará novamente caracterizada a reinserção do seu e-mail à lista de contatos da <b>NullBug</b>. Portanto, a requisição de cancelamento deverá ser realizada novamente caso seja de seu interesse.",
          p4:
            "Todos os dados coletados serão excluídos de nossos servidores quando você assim requisitar ou, ainda, quando estes não forem mais necessários ou relevantes para lhe oferecermos Produto e Serviços, salvo se houver qualquer outra razão para a sua manutenção, como eventual obrigação legal de retenção de dados ou necessidade de preservação destes para resguardo de direitos da <b>NullBug</b>.",
        },
        topic10: {
          title: "ALTERAÇÕES NO AVISO DE PRIVACIDADE",
          p1:
            "Nosso <b>Aviso</b> pode passar por atualizações. Desta forma, recomendamos visitar periodicamente nossa página para você ter conhecimento sobre as modificações.",
        },
        topic11: {
          title: "RECLAMAÇÕES",
          p1:
            'Você também tem o direito de peticionar perante a Autoridade Nacional de Proteção de Dados (ANPD), autoridade responsável por zelar pelo respeito dos direitos dos titulares de dados pessoais no Brasil e fiscalizar o cumprimento da Lei Geral de Proteção de Dados Pessoais (Lei n.º 13.709/18 ou LGPD). Acesse em: <a id="underlineLink" href="https://www.gov.br/anpd/pt-br">https://www.gov.br/anpd/pt-br</a>.',
        },
        topic12: {
          title: "DISPOSIÇÕES FINAIS",
          p1:
            'Para saber mais, acesse nosso <a id="underlineLink" href="/docs">Aviso de Privacidade</a> na íntegra.',
          p2:
            "Este documento foi modificado pela última vez em 03 de abril de 2023.",
        },
      },
      portal: {
        p1:
          "Primeiramente, recomendamos que leia nossas políticas relacionadas à Privacidade e Proteção de Dados pessoais, pois lá estão as informações mais importantes.",
        p2:
          "Contudo, visando trazer transparência e facilidade para você, em conformidade com a Lei nº 13.709/18 - Lei Geral de Proteção de Dados Pessoais (LGPD), para exercer os seus direitos como Titular dos Dados, a <b>Nullbug</b> criou esse Portal de Comunicação.",
        p3:
          'Sendo assim, realize sua solicitação direto neste <a id="underlineLink" href="/docs/portal/form">formulário de requerimento</a>.',
        p4:
          "Nele, você deverá informar seus dados e enviar documentos que comprovem que a pessoa é de fato você ou uma pessoa autorizada a agir em seu nome, bem como que justifiquem seu pedido.",
        p5:
          "Ao encaminhar a presente mensagem e fornecer os dados requeridos, o titular declara ter conhecimento e concordar com o nosso <b>Aviso de Privacidade</b>, estando ciente sobre o tratamento de seus dados para a finalidade de exercício de direitos.",
        p6:
          "As solicitações serão respondidas em formato simplificado em até <b>01 (um) dia útil</b>  a contar do recebimento da solicitação, sendo que, em caso de impossibilidade de adoção imediata da providência, responderemos no prazo acima assinalado, comunicando que:",
        p7:
          "a) não somos o agente de tratamento dos dados em questão, indicando, sempre que possível, qual seria o agente responsável;<br /> b) indicar as razões de fato ou de direito que impedem a adoção imediata da providência.",
        p8:
          "Necessitando o Titular de uma declaração clara e completa, que indique a origem dos dados, a inexistência de registro, os critérios utilizados e a finalidade do tratamento, observados os segredos comercial e industrial, o prazo será de <b>15 (quinze) dias úteis</b>, a contar do recebimento da solicitação.",
        p9: "Exerça seus direitos!",
      },
      versionHistory: "Histórico de versões",
      months: {
        january: "Janeiro",
        february: "Fevereiro",
        march: "Março",
        april: "Abril",
        may: "Maio",
        june: "Junho",
        july: "Julho",
        august: "Agosto",
        september: "Setembro",
        october: "Outubro",
        november: "Novembro",
        december: "Dezembro",
      },
      form: {
        title: "Formulário de Requerimento",
        p1:
          "Para exercer os seus direitos como Titular de Dados, preencha o formulário a seguir.",
        p2:
          "Como medida de segurança, seu pedido só será analisado se você validar seus dados e comprovar que a pessoa que solicita informações de fato é você ou uma pessoa autorizada a agir em seu nome.",
        subject: "Assunto",
        placeholder: "Selecione um assunto...",
        questions: "Dúvidas",
        suggestions: "Sugestões",
        name: "Nome Completo",
        email: "E-mail",
        reqDate: "Data do pedido",
        request: "Solicitação",
        send: "Enviar solicitação",
        requestSended:
          "A solicitação foi enviada com sucesso, logo entraremos em contato via e-mail.",
      },
      errors: {
        errorRequired: "é obrigatório",
        errorUnexpected:
          "Ocorreu um erro inesperado. Por favor, tente novamente.",
        errorContact: "Logo entraremos em contato com você!",
        errorRegistered: "Você foi cadastrado!",
        errorNameProject: "Nome do projeto",
        errorName: "Nome",
        errorEmail: "E-mail",
        errorSubject: "Assunto",
        errorCPF: "CPF",
        errorRG: "RG",
        errorReqDate: "Data do pedido",
        errorRequest: "Solicitação",
      },
    },
  },
}
